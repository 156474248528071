import React, { useState } from "react"
import { Link } from "gatsby"
import { useLockBodyScroll } from "react-use"
import { PrimaryButton, OutlineButton } from "../Button"
import Logo from "../../images/BaselineLogo.svg"
import MenuIcon from "../../images/menu.svg"
import XIcon from "../../images/x.svg"

const MobileHeader = () => {
  const [menuVisible, setMenuVisible] = useState(false)
  useLockBodyScroll(menuVisible)

  return (
    <>
      <div
        className={`lg:hidden h-20 flex items-center justify-between text-2xl px-4 ${
          menuVisible && "bg-white"
        }`}
      >
        <Link to="/">
          <img className="h-full w-auto" src={Logo} alt="logo" />
        </Link>
        <button
          onClick={() => setMenuVisible(!menuVisible)}
          className="focus:outline-none"
        >
          <img
            className="h-full w-auto"
            src={menuVisible ? XIcon : MenuIcon}
            alt="menu"
          />
        </button>
      </div>
      {menuVisible && (
        <div className="fixed top-20 bottom-0 right-0 left-0 bg-white px-4 z-50">
          <div className="grid gap-10 py-10 text-black text-2xl font-light">
            <Link to="/" activeStyle={{ color: "#888888" }}>
              Overview
            </Link>
            <Link to="/why-baseline/" activeStyle={{ color: "#888888" }}>
              Why Baseline
            </Link>
            <Link to="/who-its-for/" activeStyle={{ color: "#888888" }}>
              Who it's for
            </Link>
            <Link to="/pricing/" activeStyle={{ color: "#888888" }}>
              Pricing
            </Link>
            <Link to="/about-us/" activeStyle={{ color: "#888888" }}>
              About us
            </Link>
          </div>
          <div className="grid gap-4">
            <a href="https://app.baselineteams.com/register">
              <PrimaryButton full>Sign up now</PrimaryButton>
            </a>
            <a href="https://app.baselineteams.com/login">
              <OutlineButton full>Log in</OutlineButton>
            </a>
          </div>
        </div>
      )}
    </>
  )
}

export default MobileHeader
